import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";

import sanityClient from "../../client";

import { StyledRow, StyledMap, StyledInfo, StyledSocialIcon, StyledSocialIcons } from "../../lib/Styling";

import map from "../../assets/google-map-new.jpg";

const GOOGLE_LINK = "https://www.google.se/maps/place/V%C3%A4stra+Ringgatan+35A,+745+31+Enk%C3%B6ping/@59.6353598,17.0723029,17z/data=!4m13!1m7!3m6!1s0x465e5036c2a21f03:0x6780db5752ab7e2b!2sV%C3%A4stra+Ringgatan+35A,+745+31+Enk%C3%B6ping!3b1!8m2!3d59.6352872!4d17.0741518!3m4!1s0x465e5036c2a21f03:0x6780db5752ab7e2b!8m2!3d59.6352872!4d17.0741518";

const FooterInfo = () => {
  const [opening, setOpening] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "openingHours"]{
        body
      }`)
      .then((data) => setOpening(data[0]))
      .catch(console.error)
  }, []);

  return (
    <StyledRow>
      <StyledInfo>
        <h3>Öppettider</h3>
        {opening &&
          <BlockContent
            blocks={opening.body}
            projectId="7bkl65k0"
            dataset="production"
          />
        }
      </StyledInfo>
      <StyledInfo>
        <h3>Hitta till oss</h3>
        <a
          href={GOOGLE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledMap src={map} alt="Karta" />
        </a>
      </StyledInfo>
      <StyledInfo>
        <h3>Kontakta oss</h3>
        <p>Rum 35A</p>
        <p>Västra Ringgatan 35A</p>
        <p>745 31 Enköping</p>
        <p><a href="tel:+46709221602">0709-221602</a></p>
        <p><a href="mailto:rum35a@telia.com">rum35a@telia.com</a></p>
        <StyledSocialIcons>
          <StyledSocialIcon url="https://www.instagram.com/rum_35a" target="_blank" rel="noopener noreferrer"/>
          <StyledSocialIcon url="https://www.facebook.com/rum35a" target="_blank" rel="noopener noreferrer"/>
        </StyledSocialIcons>
      </StyledInfo>
    </StyledRow>
  );
};

export default FooterInfo;