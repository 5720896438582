import React from "react";

import FooterInfo from "./sections/FooterInfo";

import { FooterText, StyledFooter } from "../lib/Styling";

const Footer = () => {
  return (
    <StyledFooter>
      <FooterInfo />
      <FooterText>
        Rum 35A &copy; Copyright {new Date().getFullYear()}.<br />Alla rättigheter förbehållna.<br /><a href="mailto:techstorming@mindstorming.se">Hemsida skapad av Techstorming, en del av Mindstorming AB</a>
      </FooterText>
    </StyledFooter>
  )
};

export default Footer;