import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient from "../../client";

import { StyledRow, StyledImageContainer, StyledImage, StyledImageText } from "../../lib/Styling";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

const HomeCarousel = () => {
  const [carousel, setCarousel] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "homeCarousel"]{
        alt1,
        image1{
          asset->{
            _id,
            url
          }
        },
        alt2,
        image2{
          asset->{
            _id,
            url
          }
        },
        alt3,
        image3{
          asset->{
            _id,
            url
          }
        },
      }`)
      .then((data) => setCarousel(data[0]))
      .catch(console.error);
  }, []);

  return (
    <>
      {carousel &&
        <StyledRow>
          <StyledImageContainer to="/tradgardsservering">
            {/* <StyledImageText>{carousel.alt1}</StyledImageText> */}
            <StyledImageText>Trädgårds-<br />servering</StyledImageText>
            <StyledImage src={urlFor(carousel.image1).width(300).height(300).url()} alt={carousel.alt1}/>
          </StyledImageContainer>
          <StyledImageContainer to="/catering">
            {/* <StyledImageText>{carousel.alt2}</StyledImageText> */}
            <StyledImageText>Catering</StyledImageText>
            <StyledImage src={urlFor(carousel.image2).width(300).height(300).url()} alt={carousel.alt2}/>
          </StyledImageContainer>
          <StyledImageContainer to="/kok">
            {/* <StyledImageText>{carousel.alt3}</StyledImageText> */}
            <StyledImageText>Kök</StyledImageText>
            <StyledImage src={urlFor(carousel.image3).width(300).height(300).url()} alt={carousel.alt3}/>
          </StyledImageContainer>
        </StyledRow>
      }
    </>
  );
}

export default HomeCarousel;