import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient from "../../client";

import { StyledMainImage } from "../../lib/Styling";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

const CuisineImage = () => {
  const [heroImage, setHeroImage] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "cuisineImage"]{
        mainImage{
          asset->{
            _id,
            url
          }
        },
        alt
      }`)
      .then((data) => setHeroImage(data[0]))
      .catch(console.error);
  }, []);

  return (
    <>
      {heroImage &&
        <StyledMainImage
          marginTop
          src={urlFor(heroImage.mainImage).url()}
          alt={heroImage.alt}
        />
      }
    </>
  );
};

export default CuisineImage;