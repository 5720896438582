import React from "react";

import { StyledHeader, StyledMenuLogo, StyledNavBar, StyledNavLink } from "../lib/Styling";

import logo from "../assets/RUM-35A-logo-2024.png";

const NavBar = () => {
  return (
    <StyledHeader>
      <StyledMenuLogo src={logo} alt="Logga" />
      <StyledNavBar>
        <StyledNavLink to="/" exact>Hem</StyledNavLink>
        <StyledNavLink to="/tradgardsservering" exact>Trädgårdsservering</StyledNavLink>
        <StyledNavLink to="/catering" exact>Catering</StyledNavLink>
        <StyledNavLink to="/kok" exact>Kök</StyledNavLink>
      </StyledNavBar>
    </StyledHeader>
  );
};

export default NavBar;