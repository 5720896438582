import React from "react";

import HomeImage from "./sections/HomeImage";
import HomeDescription from "./sections/HomeDescription";
import HomeCarousel from "./sections/HomeCarousel";
// import HomeVideo from "./sections/HomeVideo";
// import HomeFeed from "./sections/HomeFeed";

import { StyledContainer } from "../lib/Styling";

const Home = () => {
  return (
    <StyledContainer>
      <HomeImage />
      <HomeDescription />
      <HomeCarousel />
      {/* <HomeVideo /> */}
      {/* <HomeFeed /> */}
    </StyledContainer>
  );
};

export default Home;