import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient from "../../client";

import { StyledImage, StyledRow } from "../../lib/Styling";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

const CafeCarousel = () => {
  const [carousel, setCarousel] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "cafeCarousel"]{
        alt1,
        image1{
          asset->{
            _id,
            url
          }
        },
        alt2,
        image2{
          asset->{
            _id,
            url
          }
        },
        alt3,
        image3{
          asset->{
            _id,
            url
          }
        },
      }`)
      .then((data) => setCarousel(data[0]))
      .catch(console.error);
  }, []);

  return (
    <>
      {carousel && 
        <StyledRow>
          <StyledImage src={urlFor(carousel.image1).width(300).height(300).url()} alt={carousel.alt1}/>
          <StyledImage src={urlFor(carousel.image2).width(300).height(300).url()} alt={carousel.alt2}/>
          <StyledImage src={urlFor(carousel.image3).width(300).height(300).url()} alt={carousel.alt3}/>
        </StyledRow>
      }
    </>
  );
};

export default CafeCarousel;