import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";

import sanityClient from "../../client";
import { StyledMenuBlockContent } from "../../lib/Styling";

const CuisineCatering = () => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "cuisineCatering"]{
        title,
        body
      }`)
      .then((data) => setDescription(data[0]))
      .catch(console.error)
  }, []);

  return (
    <>
      {description &&
        <>
          <h2>{description.title}</h2>
          <StyledMenuBlockContent>
            <BlockContent
              blocks={description.body}
              projectId="7bkl65k0"
              dataset="production"
            />
          </StyledMenuBlockContent>
        </>
      }
    </>
  );
};

export default CuisineCatering;