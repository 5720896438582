import React from "react";

import CafeDescription from "./sections/CafeDescription";
import CafeCarousel from "./sections/CafeCarousel";

import { StyledContainer } from "../lib/Styling";

const Cafe = () => {
  return (
    <StyledContainer>
      <CafeDescription />
      <CafeCarousel />
    </StyledContainer>
  );
};

export default Cafe;