import styled from "styled-components/macro";
import { Link, NavLink } from "react-router-dom";
import Feed from "react-instagram-authless-feed";
import { SocialIcon } from "react-social-icons";

// Styled components used in Home

export const StyledContainer = styled.main`
  flex: 1 0 auto;
  align-self: center;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
`;

export const StyledMainImage = styled.img`
  width: 100%;
  max-width: 1024px;
  object-fit: cover;
  margin-top: ${props => props.marginTop ? "1rem" : "0"};
`;

export const StyledText = styled.p`
  text-align: center;
  font-size: 1rem;
  margin: ${props => props.noMargin ? "0.25rem 0" : "1rem 0"};
  font-weight: ${props => props.bold ? "bold" : ""};
`;

export const StyledBlockContent = styled.div`
  text-align: center;
  font-size: 1rem;
`;

export const StyledMenuBlockContent = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 1rem 0;

  p {
    margin: 0;
  }

  ul {
    text-align: left;
    margin: 0;
  }
`;

export const StyledRow = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
`;

export const StyledImageContainer = styled(Link)`
  position: relative;
  color: inherit;
  margin-bottom: 1.5rem;
  transition: opacity 0.7s;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 70%;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StyledImage = styled.img`
  object-fit: cover;
  width: 260px;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    object-fit: cover;
    width: 100%;
    margin-bottom: 0;
  }
`;

export const StyledImageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,0.7);
  padding: 1rem;
  margin: 0;
  border-radius: 0.25rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
  font-size: 1.2rem;
`;

export const StyledFeed = styled(Feed)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  a {
    margin-bottom: 1.5rem;
    transition: opacity 0.7s;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      opacity: 70%;
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  img {
    object-fit: cover;
    width: 260px;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      object-fit: cover;
      width: 100%;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
`;

// Styled components used in Info

export const StyledMap = styled.img`
  object-fit: cover;
  width: 200px;
  border: 1px solid #c3c3c3;
  box-shadow: 2px 2px 2px grey;
  justify-self: center;
  align-self: center;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const StyledInfo = styled.div`
  font-size: 0.85rem;
  text-align: center;

  p {
    margin: 0.25rem;

    &:last-child {
      margin-bottom: 1rem;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: 768px) {
    align-self: start;
  }
`;

export const StyledSocialIcon = styled(SocialIcon)`
  margin: 0.25rem;
`;

export const StyledSocialIcons = styled.div`
  @media (min-width: 768px) {
    grid-column-start: 2;
    grid-row-start: 2;
  }
`;

// Styled components used in NavBar

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  text-align: center;
  max-width: 1024px;
  align-self: center;
  border-bottom: 1px solid grey;
`;

export const StyledMenuLogo = styled.img`
  object-fit: cover;
  width: 16rem;
  height: 4rem;
  max-width: 400px;
  align-self: center;
  margin-bottom: 1rem; 
`;

export const StyledNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const StyledNavLink = styled(NavLink)`
  font-size: 0.9rem;
  color: grey;
  text-decoration: none;

  &:hover {
    color: black;
  }

  &.active {
    font-style: italic;
    color: black;
  }
`;

// Styled component used in Footer

export const StyledFooter = styled.footer`
  flex-shrink: 0;
  justify-self: center;
  width: 100%;
  padding: 1.5rem;
  text-align: center;
  max-width: 1024px;
  align-self: center;
`;

export const FooterText = styled.p`
  font-size: 0.75rem;
  margin-top: 1.5rem;
`;