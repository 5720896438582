import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";

import sanityClient from "../../client";

import { StyledBlockContent } from "../../lib/Styling";

const CafeDescription = () => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "cafeDescription"]{
        title,
        body
      }`)
      .then((data) => setDescription(data[0]))
      .catch(console.error)
  }, []);

  return (
    <>
      {description &&
        <>
          <h1>{description.title}</h1>
          <StyledBlockContent>
            <BlockContent
              blocks={description.body}
              projectId="7bkl65k0"
              dataset="production"
            />
          </StyledBlockContent>
        </>
      }
    </>
  );
};

export default CafeDescription;