import React from "react";

import ShopDescription from "./sections/ShopDescription";
import ShopCarousel from "./sections/ShopCarousel";

import { StyledContainer } from "../lib/Styling";

const Shop = () => {
  return (
    <StyledContainer>
      <ShopDescription />
      <ShopCarousel />
    </StyledContainer>
  );
};

export default Shop;