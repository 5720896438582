import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Shop from "./components/Shop";
import Cafe from "./components/Cafe";
import Cuisine from "./components/Cuisine";
import Footer from "./components/Footer";

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route component={Home} path="/" exact/>
        <Route component={Shop} path="/tradgardsservering" />
        <Route component={Cafe} path="/catering" />
        <Route component={Cuisine} path="/kok" />
        <Route component={Home} path="/404" />
        <Redirect to="/404"></Redirect>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
