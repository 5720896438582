import React from "react";

import CuisineDescription from "./sections/CuisineDescription";
import CuisineKit from "./sections/CuisineKit";
import CuisineCatering from "./sections/CuisineCatering";
import CuisineImage from "./sections/CuisineImage";

import { StyledContainer } from "../lib/Styling";

const Cuisine = () => {
  return (
    <StyledContainer>
      <CuisineDescription />
      <CuisineCatering />
      <CuisineKit />
      <CuisineImage />
    </StyledContainer>
  );
};

export default Cuisine;